/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
   reset.css
*/

/* Déclaration variables globales */
:root{

    --blueColor:#2633B6;
    --roseColor:#eb3b75;
    --vertColor:#1c9f27;
    --grisColor:#757777;
    --violetColor:#740ebc;
    --jauneColor:#fbe920;


    --vertLightColor:#fffffb;
    --vertDarkColor:#aabb97;
    --vertTextColor:#000000;
    --jauneLightColor:#fffff7;
    --jauneDarkColor:#cbc693;
    --jauneTextColor:#000000;
    --roseLightColor:#ffffff;
    --roseDarkColor:#cb9ca1;
    --roseTextColor:#000000;
    --marronColor:#efebe9;
    --marronLightColor:#fffffb;
    --marronDarkColor:#a69b97;
    --marronTextColor:#000000;
    --bleuEnregistrer:#d4e7ff;
    --orangeColor:#e9f7f5;
    --marronBisColor:#f4e6d9;
}

.titre{
    font-style: italic;
    font-size: 0.80em;
    text-align: center;
}

.noir_petit{
    font-size: 1em;
    text-align: left;
}
.bleu_fonce, .noir_fonce{
    font-size: 1.2em;
    font-weight: bolder;
    text-align: left;
}

.bleu_fonce{
    color: var(--blueColor) !important;
}


/* CSS de mise en page - Bootstrap forced */
.d-flex {
    display:-ms-flexbox!important;
    display:flex;
}
.flex-row {flex-direction:row;}
.mx-auto {margin-left:auto; margin-right:auto;}
.my-auto {margin-top:auto; margin-bottom:auto;}

.header-logo {margin:0 auto 0 0; padding:15px;}

.navbar-toggle {
    background-color:#ececec!important;
    border-color:transparent!important;
    margin:0 0 0.5rem 0;
    padding:0;
}

.navbar-toggle.collapsed {
    font-family: 'FontAwesome';
    font-style:"normal";
    font-weight:'900';
}


@media screen and (max-width:576px) {
    .navbar-default.flex-row {
        flex-direction:column!important;
        position: sticky;
        top: 0;
        z-index: 9999;}
    .header-logo {margin:0 auto!important; padding:1rem!important;}
    .navbar-nav {margin:0!important;}
    .navbar-default .navbar-nav>li>a {text-align:center;}
    .navbar-collapse.collapse, .navbar-collapse.collapsing {border-top:0!important;padding:0;background:#ececec;}

}

.row {
    margin-left:0!important;
    margin-right:0!important;
}

.wrapper {
    padding:0!important;
    padding:0!important;
}

/* CSS de l'application */
/* base.html.twig */
body {
    background-color: #F5F5F5;
    font-family : Roboto, Colibri, Verdana, Arial, Helvetica, sans-serif;
    font-size: 14px;
}

form legend {
    display: none;
}

.navbar-brand {font-size:1.5rem;padding:0;height:auto;}
.navbar-brand img {width:22px; height:22px; margin:auto 0;}

/* On indique tout simplement une marge */
.margin-constraint{
    margin-bottom: 8.5em;
}

/* Positionnement du texte à côté di logo */
.GreenGarden {
    color:var(--marronDarkColor);
    margin-top: 1.15em;
}

/* nom des menus de la barre de navigation en bas de page */
.textemenu{
    font-size:1.1em;
}

/* Marge de toutes les pages index, le premier div dans BODY */
.wrapper {
    margin-right: auto;
    margin-left:  auto;
    padding-right: 1rem;
    padding-left:  1rem;
    max-width: 700px;
}

.cadre_post{
    padding: 1em 4em;
    margin-bottom: 1em;
}

.deco_colorblue{
    text-decoration-color: var(--blueColor) !important;
    text-decoration-thickness: 0.15em;
}

.colorblue {
    color: var(--blueColor) !important;
    font-weight : bold !important;
}

.colorviolet {
    color: var(--violetColor) !important;
    font-weight : bold !important;
}

.colorrose {
    color: var(--roseColor) !important;
    font-weight : bold !important;
}

.colorvert {
    color: var(--vertColor) !important;
    font-weight : bold !important;
}

.colorgris {
    color: var(--grisColor) !important;
    font-weight : bold !important;
}

/* Cadre Blanc arrondi, associé avec la class ROW */
.cadre_blanc{
    background-color: white;
    padding: 1em 0.5em;
    border-bottom :var(--vertColor) 1px solid;
}

.cadre_blanc_no_border{
    background-color: white;
    padding: 1em 0.5em;
    margin-bottom: 1em;
}

.fond{
    background-color: white;
    padding-left: 1em;
    padding-right: 1em;
    padding-top : 1em;
}

/* Cadre transparent pour les sauts de ligne, associé avec la class ROW */
.cadre_transparent{
    padding: 0.5em 0.5em;
    margin-bottom: 0.5em;
}

/* Les blocs déroulants dans le jardin et le carnet (index et show) */
summary::-webkit-details-marker {
    display: none;
}

.deroulfloatl
{
    float: left;
}
.deroulfloatr
{
    float: right;
}


.noborder td, .noborder th {
    border: none !important;
}

.mdl-button-rond {
    background: transparent;
    border: none;
    color: rgb(0,0,0);
    position: relative;
    width: 100%;
    padding: 0.5em;
    display: inline-block;
    overflow: hidden;
    will-change: box-shadow;
    transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1), color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    outline: none;
    cursor: pointer;
    text-decoration: none;
    text-align: center;
    vertical-align: middle; }

.mdl-button {
    background: transparent;
    border: none;
    color: rgb(0,0,0);
    position: relative;
    width: 100%;
    margin: 0;
    min-width: 64px;
    padding: 1em 1.5em;
    display: inline-block;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 14px;
    letter-spacing: 0;
    overflow: hidden;
    will-change: box-shadow;
    transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1), color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    outline: none;
    cursor: pointer;
    text-decoration: none;
    text-align: center;
    line-height: 36px;
    vertical-align: middle; }
.mdl-button img{
    float:left;
    margin-right:0.5em;
}
.mdl-button::-moz-focus-inner {
    border: 0; }
.mdl-button:hover {
    background-color: rgba(158,158,158, 0.20); }
.mdl-button:focus:not(:active) {
    background-color: rgba(0,0,0, 0.12); }
.mdl-button:active {
    background-color: rgba(158,158,158, 0.40); }
.mdl-button.mdl-button--vert {
    color: #fffff7; }
.mdl-button.mdl-button--rose {
    color: #fffff7; }
.mdl-button.mdl-button--jaune {
    color: #fffff7; }
.mdl-button.mdl-button--marron {
    color: #fffff7; }
.mdl-button.mdl-button--orange{
    color: #fffff7; }
.mdl-button.mdl-button--bleufonce{
    color: #fffff7; }
.mdl-button.mdl-button--bleu {
    color: #fffff7; }
.mdl-button.mdl-button--colored:focus:not(:active) {
    background-color: rgba(0,0,0, 0.12); }

.mdl-button--raised {
    background: rgba(210,210,210, 0.20);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12); }
.mdl-button--raised:active {
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
    background-color: rgba(158,158,158, 0.40); }
.mdl-button--raised:focus:not(:active) {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.18), 0 8px 16px rgba(0, 0, 0, 0.36);
    background-color: rgba(158,158,158, 0.40); }
.mdl-button--raised.mdl-button--vert {
    background: var(--vertColor);
    color: rgb(255, 255, 255); }
.mdl-button--raised.mdl-button--jaune {
    background: var(--jauneColor);
    color: rgb(0,0,0); }
.mdl-button--raised.mdl-button--rose {
    background: var(--roseColor);
    color: rgb(255, 255, 255); }
.mdl-button--raised.mdl-button--marron {
    background: var(--marronColor);
    color: rgb(0,0,0); }
.mdl-button--raised.mdl-button--orange {
    background: var(--orangeColor);
    color: rgb(0,0,0); }
.mdl-button--raised.mdl-button--bleufonce {
    background: var(--blueColor);
    color: rgb(255, 255, 255); }
.mdl-button--raised.mdl-button--bleu {
    background: var(--bleuEnregistrer);
    color: rgb(0,0,0); }
.mdl-button--raised.mdl-button--colored:hover {
    background-color: #fffff7; }
.mdl-button--raised.mdl-button--colored:active {
    background-color: #fffff7; }
.mdl-button--raised.mdl-button--colored:focus:not(:active) {
    background-color: #fffff7; }
.mdl-button--raised.mdl-button--colored .mdl-ripple {
    background: rgb(0,0,0); }

.mdl-shadow--2dp {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12); }

.navbar-inverse {
    /*  background-color:#CDFF9B; */
    background-color:#FFFFFF;
}
.navbar-default{
    background-color: #F5F5F5;
}
.alert { height: auto; padding: 1px;
}

/* Affichage des heures et dates des paniers, post et commentaires */
.dateheure {
    font-size: 0.8em;
    font-style: italic;
    margin: auto 0 auto auto;
}
/*
@media screen and (max-width:576px) {
    .dateheure {
        margin-left:auto;
    }
}
*/
.zonebutton{
    text-align:justify;
    line-height:1.3em;
    margin: 1em;
    /*  max-height:380px;
      overflow:auto; */
}

.zonecom{
    text-align: left;
    line-height: 1.3em;
    margin: 0 1em;
    display: flex;
    flex-direction: column;
}

.zonecom.iteration {
    margin:1rem;
}

.myDIVcomload{
    margin: 1em;
}

.zonecom div:first-child {
    display:flex;
}

/*
.zonecom span:last-child {
    margin-top: 0.5rem;
}
*/
.contact{
    margin: 5% 20% ;
    /*  max-height:380px;
      overflow:auto; */
}

.calendar{
    width:100% ;
}

.calendar td{
    border :1px solid darkgray ;
    padding: 0.3em 0.3em;
    text-align: left;
    vertical-align: top;
    width: 14.7%;
    height:4em;
}

.calendar td img{
    margin : 10% auto 5% auto;
    display: block;
    vertical-align: middle;
}

.calendar_week{
    font-style: italic;
    font-size: 0.7em;
}

.calendar_day{
    font-weight: bold;
    color:#cb9ca1;
    font-size: 0.7em;
}

.calendar_day_opacity{
    font-weight: bold;
    color:#cb9ca1;
    font-size: 0.7em;
    opacity: 0.4;
}



/* Affichage bloc nouveau commentaire */
.commentaire {
    width:100%;
}

/*
Style des tables pour les formulaires d'ajout (plantation, récoltes et aménagement) dans le dossier template/ajouter
*/

.table_ajout {
    width:100%;
    border:0;
}

/*
Style des tables pour l'affichage des stat dans le dossier template/garden, et des photos dans panier et post
*/
.table_jardin th {
    text-align:center
}

.table {
   margin-bottom: 0; 
}

.table_jardin tr td, .table_jardin td {
    text-align:right
}


/*
Affichage du bloc Photo et description des Paniers et Posts dans flux/index_panier.html.twig et flux/index_post.html.twig
 */

.panel p{
    text-align:center;
}

.panel img{
    text-align: center;
    display: table-cell;
    vertical-align: middle;
}

.mdl-card{
    object-fit: cover; /* Do not scale the image */
    object-position: center; /* Center the image within the element */
    height: 500px;
    width: 500px;
}
/* flux/index_panier.html.twig : Ici le CSS pour le bouton PANIER, dans l'ACCUEIL, qui permet de faire apparaitre le détail des récoltes dans le volet */

/* tous les template dans flux, et base.html.twig : Ici le CSS pour l'alignement qui permet de mettre en forme les éléments au dessus et en dessous de la photo du flux */

.menu
{
    width:100%;
    margin: -0.60em 0;
    padding:0;
    list-style-type: none;
    text-align: center;
}
.menu li
{
    text-align: center;
    margin:auto;
    padding: 0;
    vertical-align:middle;
    display: inline-block;

}
.menu li img{
    vertical-align:middle;
    text-align: center;
}

.menu li a{
    text-align: center;
}

.menu li span{
    vertical-align:middle;
    text-align: center;
}

.menufloatl{
    float:left;
    margin:0.1em;
}

.menufloatl img{
margin-left: 1em;
}

.menufloatr{
    float:right;
}


/*
Affichage du pseudo de l'auteur de la photo des Paniers et Posts dans flux/index_panier.html.twig et flux/index_post.html.twig
 */
.nom_user{
    color:black;
    margin: auto 0.5rem;
    font-weight:bold;
    text-decoration:none!;
}


.tof{
    margin-top:5%;	
    display: block;		
    padding-bottom: calc(95%*1/1);
    position: relative;
}

.tof img{
    position: absolute;
    top: 0;	
    left: 0;	
    width: 100%;	
    height: 100%;	
    display: flex;
    align-items: center;
    justify-content: center;
}

.mosaique {
    justify-content : space-between;
    align-content: center;
    flex-wrap:  wrap;
    display: flex;
    margin : auto auto 0.4em auto;
}

/*
Affichage pseudo, avatar dans profil et garden (index et show)
+ Voir son Profil//Voir son jardin dans garden/show.html.twig et profile/show.html.twig
 */

.entete_user{
    padding-left: 1em;
    padding-right: 1em;
    margin: 0;
    text-align:center;
    float:left;
    width:35%;
}

.entete_stat{
    padding-left: 1em;
    margin: 0;
    text-align:left;
    border-left: 1px solid #ebebeb;
    float:left;
    width:65%;
}

/* Par défaut, une étoile est en gris,
   avec un padding et un curseur en forme de main. */
.fa-star {
    color: gray;
    cursor: pointer;
    padding: 0.0625rem;
    font-size: 2em;
}

/* Si elle porte en plus la classe '.gold', elle sera en jaune. */
.fa-star.gold {
    color: #ffdc0f;
}

/* Le parent global '.rating' positionne le groupe des étoiles et le lien en colonne */
.rating {
    align-items: center;
    color:#a69b97;
    border:2px solid #a69b97;
    padding:0.5em;
    background-color: white;
}

.rating_no {
    align-items: center;
    color:#ffdc0f;
    border:2px solid #ffdc0f;
    padding:0.5em;
    background-color: white;
}

.meilleur {
    display: table-cell;
    float:left;
    align-items: center;
    color:#008c8c;
    border:1px solid #008c8c;
    padding:0.5em;
    margin-left:0.5em;
    margin-bottom:0.5em;
    background-color: white;
}

/* Points colorés */

.cercle_violet {
    width: 15px;
    height: 15px;
    border-radius: 20px;
    background: var(--violetColor);
    float:right;
}

.cercle_jaune {
    width: 15px;
    height: 15px;
    border-radius: 20px;
    background: var(--jauneColor);
    float:right;
}
.cercle_vert {
    width: 15px;
    height: 15px;
    border-radius: 20px;
    background: var(--vertColor);
    float:right;
}

.cercle_rose {
    width: 15px;
    height: 15px;
    border-radius: 20px;
    background: var(--roseColor);
    float:right;
}
.cercle_bleu {
    width: 15px;
    height: 15px;
    border-radius: 20px;
    background: var(--blueColor);
    float:right;
}
.cercle_gris {
    width: 15px;
    height: 15px;
    border-radius: 20px;
    background: var(--grisColor);
    float:right;
}

#conteneur
{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
}
.element
{
    margin: auto;
    padding-bottom: 0.6em;
}


.chevron
{
    margin-top:0.5em;
    padding:0.3em;
    border-radius: 25px;
    background-color:#ebebeb;
}

.dbt_block{
    border-top: 2px solid gray;
}

.fin_block{
    margin: 3em;
}

.upload-image-strip input[type='file'] {
    position: absolute;
    margin-top: 3px;
    margin-left: 3px;
    height: 1px;
    width: 1px;
    z-index: -5;
}

.upload-image-strip label {
    display: none;
}



